import { Buildings, Stamp, MedalMilitary, Gavel } from '@phosphor-icons/react'
import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import marriageRingsImage from '../images/marriage-black.png'

export const serviceTypes = [
  ['Консульскую справку', 'Consular certificate'],
  ['Истребовать документ из России', 'Request a document from Russia'],
]

export const certificateTypes = [
  ['Справка о несудимости', 'Criminal record certificate'],
  ['Заявление о брачной правоспособности', 'Marriage capacity certificate'],
  [
    'Справка о ПМЖ за пределами РФ',
    'Permanent residence certificate outside the Russian Federation',
  ],
  ['Акт о личной явке', 'Personal attendance act'],
  [
    'Справка о работе за пределами РФ',
    'Work certificate outside the Russian Federation',
  ],
]

export const documentTypes = [
  ['Документ ЗАГС', 'Civil registry document'],
  ['Справка о семейном положении', 'Certificate of marital status'],
  [
    'Справка о стаже и трудовой деятельности',
    'Certificate of work experience and employment',
  ],
  ['Справка о прохождении воинской службы', 'Certificate of military service'],
]

const vocabularyCertificates = {
  certificates: ['Справки', 'Certificates'],
  certificatesText1: [
    'Для получения консульских справок требуется представить заявление, которое в своё очередь будет должным образом заверено консульским работником.',
    'To obtain consular certificates, it is necessary to submit an application, that will be properly certified by consular staff.',
  ],
  certificatesText2: [
    'Мы поможем вам составить заявление в требуемом формате, проконсультируем по списку документов и записи на приём в консульство или запишем вас сами, и разместим готовый проект документа на консульском портале.',
    'We will assist you in drafting the application in the required format, provide consultation on the list of documents and scheduling an appointment  at the consulate, or schedule an appointment for you, and submit the prepared draft document on the consulate portal.',
  ],
  typesOfCertificates: [
    {
      section: ['Консульские справки:', 'Consular certificates'],
      types: [
        {
          id: '0',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[0][1],
          text: [
            'На Справка о несудимости',
            'Certificate of No Criminal Record',
          ],
          icon: <Gavel size={24} weight='light' />,
        },
        {
          id: '1',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[1][1],
          text: [
            'Заявление о брачной правоспособности',
            'Marriage Eligibility Statement',
          ],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '2',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[2][1],
          text: [
            'Справка о ПМЖ за пределами РФ',
            'Certificate of Permanent Residency Abroad (outside the Russian Federation)',
          ],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: '3',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[3][1],
          text: ['Акт о личной явке', 'Personal Appearance Report'],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: '4',
          service: serviceTypes[0][1],
          additionalServiceType: certificateTypes[4][1],
          text: [
            'Справка о работе за рубежом',
            'Verification of Employment Abroad',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      id: 'documents-retrieval',
      section: ['Истребование документов', 'Document retrieval'],
      types: [
        {
          id: '5',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[0][1],
          text: ['Документы ЗАГС', 'Civil Registry Office (ZAGS) documents'],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '6',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[1][1],
          text: [
            'Справка о семейном положении',
            'Certificate of marital status',
          ],
          icon: <img src={marriageRingsImage} alt='visa' width='24px' />,
        },
        {
          id: '7',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[2][1],
          text: [
            'Справка о стаже и трудовой деятельности',
            'Certificate of employment history and work experience',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: '8',
          service: serviceTypes[1][1],
          additionalServiceType: documentTypes[3][1],
          text: [
            'Справка о прохождении воинской службы',
            'Military service record',
          ],
          icon: <MedalMilitary size={24} weight='light' />,
        },
      ],
    },
  ],
  certificatesText3: [
    'Срок истребования документов может составлять до 6 месяцев.',
    'The document retrieval process may take up to 6 months.',
  ],
  certificatesText4: [
    'Дополнительно можно запросить проставить на запрошенный документ апостиль.',
    'Additionally, you can request that an apostille be affixed to the requested document.',
  ],
  orderDescription: [
    [
      {
        headerText: 'Оформите заявку',
        cardText:
          'Опишите, для каких целей требуется справка. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста заявления',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект заявления и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для получения консульской услуги',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submit an Application',
        cardText:
          'Describe for what purposes the certificate is required. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of the Application Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft application, and post it on the MFA Russia portal. You will receive a placement number that you need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with your passport and accommodation number to receive consular service.',
        cardImage: stampImage,
      },
    ],
  ],
  list1: [
    [
      'внутренний паспорт и/или загранпаспорт РФ',
      'Internal passport and/or Russian international passport.',
    ],
    [
      'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
      'Address of residence abroad in Russian language (if there is no registration in Russia).',
    ],
    [
      'документы, подтверждающие родство (если запрашиваются документы на детей или умерших родственников) или представительство (если документы запрашиваются третьим лицом по нотариально удостоверенной доверенности)',
      'Documents confirming kinship (if documents for children or deceased relatives are requested) or representation (if documents are requested by a third party based on a notarized Power of Attorney).',
    ],
  ],

  certificatesText5: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the processing of notarial documents, priority is given to the internal passport. In case the internal passport has not been obtained or has expired, a valid international passport will be required. If both passports are available, it is necessary to bring both passports to the consulate appointment.',
  ],
  certificatesText6: [
    'Для справки о составе семьи дополнительно потребуются:',
    'For a family composition certificate, the following additional documents will be required:',
  ],

  list2: [
    [
      'свидетельство о браке (если состояли в браке) и его расторжении (или смерти супруга)',
      'Marriage certificate (if married) and its dissolution (or death of the spouse).',
    ],
    ['свидетельства о рождении детей', 'Birth certificates of children.'],
  ],
  certificatesText7: [
    'Для справок по вопросам пенсионных выплат дополнительно могут потребоваться:',
    'For inquiries regarding pension payments, the following additional documents may be required:',
  ],
  list3: [
    ['прописка по месту проживания', 'Registration at the place of residence.'],
    [
      'справка с места работы в стране проживания (если имеется)',
      'Certificate from the place of employment in the country of residence (if available).',
    ],
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'подготовим проект заявления',
        'разместим документ на консульском портале (если этого требует процедура)',
        'проконсультируем по перечню документов, необходимых для получения справки',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'Without Appointment at the Consulate',
      list: [
        'Prepare a draft application',
        'Submit the document on the consulate portal (if required by the procedure)',
        'Consult on the list of documents required for obtaining a certificate',
        'Consult on independently scheduling an appointment at the consulate',
      ],
      value: '40',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'подготовим проект заявления',
        'разместим документ на консульском портале (если этого требует процедура)',
        'проконсультируем по перечню документов, необходимых для получения справки',
        'запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With Appointment at the Consulate',
      list: [
        'Prepare a draft application',
        'Submit the document on the consulate portal (if required by the procedure)',
        'Consult on the list of documents required for obtaining a certificate',
        'Schedule an appointment for you at the consulate',
      ],
      value: '60',
    },
  ],
  faq: [
    {
      userName: [
        'Как я могу получить справку об отсутствии судимости?',
        'How can I obtain a certificate of no criminal record?',
      ],
      desc: [
        [
          'Первый способ: через консульство или посольство России. Срок оформления 2–4 недели.',
          'The first method: through the consulate or embassy of Russia. Processing time is 2–4 weeks.',
        ],
        [
          'Второй способ: через МВД по месту регистрации в России лично или по доверенности. Срок оформления — 1 месяц. Плюс дополнительно обычно на такой документ, оформленный в России, потребуется проставить «апостиль».',
          "The second method: through the Ministry of Internal Affairs (MVD) at the place of registration in Russia, either in person or through a Power of Attorney. Processing time is 1 month. Additionally, usually an 'apostille' will be required for such a document issued in Russia.",
        ],
      ],
    },
    {
      userName: [
        'Для заключения брака с иностранцем требуют предоставить справку о том, что я не состою в другом браке. Как её получить?',
        'To marry a foreigner, you need to provide a certificate stating that you are not currently married. How can you obtain it?',
      ],
      desc: [
        [
          'Первый способ: по доверенности в России или через консульство (процесс занимает 2–6 месяцев) истребовать в ЗАГС справку о том, что вы на данный момент не состоите в браке. На основании этой справки получить нужный документ в консульстве.',
          'First method: Through a Power of Attorney in Russia or through the consulate (the process takes 2–6 months) request a certificate from the Civil Registry Office (ZAGS) stating that you are not currently married. Based on this certificate, obtain the required document at the consulate.',
        ],
        [
          'Второй способ: заверить в консульстве заявление о семейном положении (оно же: о составе семьи, о брачной правоспособности), где вы под личную ответственность указываете сведения о ваших прошлых браках и несовершеннолетних детях и подтверждаете, что не имеете препятствий для заключения брака в данный момент. Документ, оформленный вторым способом принимается не во всех странах.',
          'Second method: Certify a statement about your marital status (also about family composition, marriage eligibility) at the consulate. In this statement, under personal responsibility, you provide information about your past marriages and underage children and confirm that you have no impediments to marry at the moment. The document issued by the second method is not accepted in all countries."',
        ],
      ],
    },
    {
      userName: [
        'Можно ли через консульство истребовать дубликат диплома ВУЗа?',
        'Is it possible to request a duplicate of a university diploma through the consulate?',
      ],
      desc: [
        [
          'Нет, по истребованию дубликатов документов об образовании необходимо обращаться непосредственно в адрес учебного заведения или ВУЗа.',
          'No, for requesting duplicates of education documents, you need to contact the educational institution or university directly.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I obtain an apostille for a document from the Civil Registry Office (ZAGS) at the consulate?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем',
          'No, the consulate cannot apostille documents issued in Russia. However, through the consulate, you can request a duplicate document from the Civil Registry Office (ZAGS) in Russia with an apostille already affixed to it.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question: 'Как я могу получить справку об отсутствии судимости?',

        answer:
          'Первый способ: через консульство или посольство России. Срок оформления 2–4 недели.',

        answer2:
          'Второй способ: через МВД по месту регистрации в России лично или по доверенности. Срок оформления — 1 месяц. Плюс дополнительно обычно на такой документ, оформленный в России, потребуется проставить «апостиль».',
      },
      {
        question:
          'Для заключения брака с иностранцем требуют предоставить справку о том, что я не состою в другом браке. Как её получить?',

        answer:
          'Первый способ: по доверенности в России или через консульство (процесс занимает 2–6 месяцев) истребовать в ЗАГС справку о том, что вы на данный момент не состоите в браке. На основании этой справки получить нужный документ в консульстве.',
        answer2:
          'Второй способ: заверить в консульстве заявление о семейном положении (оно же: о составе семьи, о брачной правоспособности), где вы под личную ответственность указываете сведения о ваших прошлых браках и несовершеннолетних детях и подтверждаете, что не имеете препятствий для заключения брака в данный момент. Документ, оформленный вторым способом принимается не во всех странах.',
      },
      {
        question:
          'Можно ли через консульство истребовать дубликат диплома ВУЗа?',

        answer:
          'Нет, по истребованию дубликатов документов об образовании необходимо обращаться непосредственно в адрес учебного заведения или ВУЗа.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',

        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем',
      },
    ],

    [
      {
        question: 'How can I obtain a certificate of no criminal record?',

        answer:
          ' The first method: through the consulate or embassy of Russia. Processing time is 2–4 weeks.',

        answer2:
          "The second method: through the Ministry of Internal Affairs (MVD) at the place of registration in Russia, either in person or through a Power of Attorney. Processing time is 1 month. Additionally, usually an 'apostille' will be required for such a document issued in Russia.",
      },
      {
        question:
          'To marry a foreigner, you need to provide a certificate stating that you are not currently married. How can you obtain it?',

        answer:
          'First method: Through a Power of Attorney in Russia or through the consulate (the process takes 2–6 months) request a certificate from the Civil Registry Office (ZAGS) stating that you are not currently married. Based on this certificate, obtain the required document at the consulate.',
        answer2:
          'Second method: Certify a statement about your marital status (also about family composition, marriage eligibility) at the consulate. In this statement, under personal responsibility, you provide information about your past marriages and underage children and confirm that you have no impediments to marry at the moment. The document issued by the second method is not accepted in all countries."',
      },
      {
        question:
          'Is it possible to request a duplicate of a university diploma through the consulate?',

        answer:
          'No, for requesting duplicates of education documents, you need to contact the educational institution or university directly.',
      },
      {
        question:
          'Can I obtain an apostille for a document from the Civil Registry Office (ZAGS) at the consulate?',

        answer:
          'No, the consulate cannot apostille documents issued in Russia. However, through the consulate, you can request a duplicate document from the Civil Registry Office (ZAGS) in Russia with an apostille already affixed to it.',
      },
    ],
  ],
  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Справка о несудимости (об отсутствии или наличии судимости)',
          'Certificate of no criminal record (on the absence or presence of a criminal record).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Данный документ подтверждает, что человек был или не был судим на территории России.',
          'This document confirms whether a person has or has not been convicted in Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Получить справку может любой гражданин РФ или иностранец старше 14 лет.',
          'Any citizen of the Russian Federation or a foreigner over 14 years old can obtain a certificate.',
        ],
      },
      {
        type: 'list',
        title: [
          'В каких ситуациях требуется:',
          'In which situations is it required:',
        ],
        items: [
          ['Оформление виз и ВНЖ', 'Obtaining visas and residence permits'],
          ['Трудоустройство', 'Employment'],
          ['Получение лицензий и разрешений', 'Obtaining licenses and permits'],
          ['Усыновление или опека', 'Adoption or guardianship'],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок оформления через консульство — от 2 до 4 недель.',
          'Processing time through the consulate is 2 to 4 weeks.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок её действия, как правило, не превышает 90 дней.',
          'Its validity period usually does not exceed 90 days.',
        ],
      },
      {
        type: 'list',
        title: [
          'Документы для оформления:',
          'Documents required for application:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
          ],
          [
            'последний адрес регистрации в России',
            'last registration address in Russia',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление о брачной правоспособности (о составе семьи, о семейном положении или статусе)',
          'Statement of marital capacity (about the family composition, marital status or status).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для регистрации брака за границей необходимо предоставить документ, подтверждающий отсутствие препятствий к этому. Таким документом может быть выписка из реестра ЗАГС о том, что на данный момент гражданин не состоит в браке в РФ, однако истребовать такую справку через консульство может занять от 2 до 6 месяцев.',
          'To register a marriage abroad, it is necessary to provide a document confirming the absence of obstacles to this. Such a document may be an extract from the civil registry office stating that the citizen is not currently married in the Russian Federation, but obtaining such a certificate through the consulate may take from 2 to 6 months.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Как альтернативу, некоторые страны принимают в качестве документа о брачной правоспособности заверенное в консульстве заявление, в котором гражданин под свою ответственность расписывается в том, что препятствий к браку не имеет и указывает другие сведения о своём семейном положении.',
          'As an alternative, some countries accept a notarized statement in the consulate as a document of marital capacity, in which the citizen, under their own responsibility, declares that there are no obstacles to the marriage and provides other information about their marital status.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления такого заявления потребуются:',
          'To issue such a statement, the following documents are required:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
          ],
          [
            'свидетельство о браке (если состояли в браке) и его расторжении (или смерти супруга)',
            'marriage certificate (if married) and its dissolution (or death of the spouse)',
          ],
          [
            'свидетельства о рождении детей, если на момент оформления заявления они не достигли 18 лет',
            'birth certificates of children, if at the time of the application they are under 18 years old',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о подтверждении ПМЖ за пределами России',
          'Certificate of confirmation of permanent residence outside Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Одна из справок, которая может потребоваться для оформления пенсионных выплат гражданам, проживающим за рубежом.',
          'One of the certificates that may be required for processing pension payments to citizens living abroad.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
          ],
          [
            'регистрация в стране проживания',
            'registration in the country of residence',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Акт о личной явке', 'Act of personal appearance.'],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, подтверждающий, что человек жив, и требуемый для продолжения пенсионных выплат в следующем периоде гражданам, проживающим за рубежом.',
          'A document confirming that a person is alive, required for the continuation of pension payments in the next period for citizens living abroad.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
        ],
        items: [
          ['загранпаспорт РФ', 'foreign passport of the Russian Federation'],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о выполнении (невыполнении) оплачиваемой работы за пределами РФ',
          'Certificate of completion (non-completion) of paid work outside the Russian Federation.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Одна из справок, которая может потребоваться для оформления пенсионных выплат гражданам, проживающим за рубежом.',
          'One of the certificates that may be required for processing pension payments to citizens living abroad.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
        ],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority is given to the internal passport)',
          ],
          [
            'справка с места работы, официальным способом переведённая на русский язык',
            'certificate from the workplace, officially translated into Russian',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Документы ЗАГС (свидетельство о рождении, смерти, браке, разводе, смене имени)',
          'Civil Registry Office Documents (birth, death, marriage, divorce, name change certificates).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не могут быть истребованы повторные свидетельства о заключении брака, если он расторгнут, а также свидетельства о рождении на умерших.',
          'Repeated marriage certificates cannot be requested if the marriage is dissolved, as well as birth certificates for deceased persons.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельства о рождении несовершеннолетних истребуются их законными представителями.',
          'Birth certificates of minors are requested by their legal representatives.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельства о смерти могут быть истребованы родственниками умерших или юридическими лицами для официальных целей.',
          'Death certificates can be requested by the relatives of the deceased or by legal entities for official purposes.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о семейном положении',
          'Certificate of marital status.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, представляющий собой выписку из единого реестра органов ЗАГС, и подтверждающий, что гражданин на данный момент не состоит в браке.',
          'A document representing an extract from the unified registry of civil status acts, confirming that the citizen is currently not married.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Требуется для заключения брака с иностранцем.',
          'Required for marriage with a foreigner.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок истребования может составлять от 2 до 6 месяцев.',
          'The request period may take from 2 to 6 months.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о стаже и трудовой деятельности',
          'Certificate of employment history and work activity.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не подлежат истребованию, пересылке и вывозу за границу подлинники трудовых и пенсионных книжек.',
          'Original work and pension books are not subject to request, mailing, or export abroad.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде за пределы России для проживания указанные документы сдаются в соответствующие организации по месту жительства в России.',
          'When leaving Russia for residence, the specified documents are submitted to the appropriate organizations at the place of residence in Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На основании сведений, содержащихся в данных документах, в установленном порядке оформляются справки о стаже и трудовой деятельности.',
          'Based on the information contained in these documents, certificates of employment history and work activity are issued in the prescribed manner.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Справка о прохождении воинской службы',
          'Certificate of military service.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Не подлежат истребованию, пересылке и вывозу за границу военные билеты.',
          'Military IDs are not subject to request, mailing, or export abroad.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде за пределы России для проживания указанные документы сдаются в соответствующие организации по месту жительства в России.',
          'When leaving Russia for residence, the specified documents are submitted to the appropriate organizations at the place of residence in Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На основании сведений, содержащихся в данных документах, в установленном порядке оформляются справки о прохождении воинской службы.',
          'Based on the information contained in these documents, certificates of military service are issued in the prescribed manner.',
        ],
      },
    ],
  ],
  workflow: {
    certificateOrDocument: {
      title: ['Что вы хотите оформить?', 'What do you want to arrange?'],
      options: [...serviceTypes, ['Не знаю', 'Not sure']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    certificateType: {
      title: ['Какая справка вам необходима?', 'What certificate do you need?'],
      options: [...certificateTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    documentType: {
      title: [
        'Какой документ вы хотите получить?',
        'What document do you want to obtain?',
      ],
      options: [...documentTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
      apostile: ['Заверенный апостилем', 'Apostile'],
    },
    civilRegistryType: {
      title: [
        'Какое свидетельство ЗАГС необходимо получить?',
        'What civil registry certificate do you need to obtain?',
      ],
      options: [
        ['о рождении (свое)', 'Birth certificate (own)'],
        ['о рождении (ребенку)', 'Birth certificate (child)'],
        ['о смерти (родственника)', 'Death certificate (relative)'],
        ['о браке или разводе', 'Marriage or divorce certificate'],
        ['о перемене имени', 'Name change certificate'],
      ],
    },
    requestAdditionalInformation: {
      title: ['Дополнительные сведения', 'Additional information'],
      additionalInfo: [
        'Опишите своими словами, для каких целей будет использоваться документ, номер и дату выдачи (если имеется)',
        'Describe in your own words, for what purpose the document will be used, the number and date of issuance (if applicable)',
      ],
    },
  },
  requiredDocumentsText: [
    'Базовый комплект документов, которые потребуются для подготовки заявления, включает в себя:',
    'The basic set of documents that will be required to prepare an application includes:',
  ],
}

export default vocabularyCertificates
