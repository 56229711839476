import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

import marriageRingsImage from '../images/marriage-black.png'

import {
  Baby,
  Buildings,
  Coins,
  FileText,
  House,
  Stamp,
} from '@phosphor-icons/react'

export const serviceTypes = [
  ['Согласие', 'Consent'],
  ['Заявление', 'Application'],
  ['Договор, соглашение', 'Agreement'],
]

export const consentTypes = [
  [
    'На выезд ребенка за пределы РФ',
    'For the child to travel outside the Russian Federation',
  ],
  [
    'От супруга на покупку недвижимости',
    'From spouse for the purchase of real estate',
  ],
  [
    'От супруга на продажу недвижимости',
    'From spouse for the sale of real estate',
  ],
  [
    'От супруга на дарение недвижимости',
    'From spouse for the donation of real estate',
  ],
  [
    'От супруга на залог недвижимости',
    'From spouse for the pledge of real estate',
  ],
  [
    'От супруга на покупку доли в ООО',
    'From spouse for the purchase of a share in LLC',
  ],
  [
    'От супруга на продажу доли в ООО',
    'From spouse for the sale of a share in LLC',
  ],
  [
    'От супруга на изменение доли в ООО',
    'From spouse for the change of a share in LLC',
  ],
  [
    'От супруга на дарение доли в ООО',
    'From spouse for the donation of a share in LLC',
  ],
  [
    'От супруга на залог доли в ООО',
    'From spouse for the pledge of a share in LLC',
  ],
]

export const applicationTypes = [
  ['На вступление в наследство', 'For inheritance entry'],
  ['На отказ от наследства', 'For renunciation of inheritance'],
  ['На снятие с регистрационного учета', 'For deregistration'],
  ['На выплату пенсии', 'For pension payment'],
  ['Завещание', 'Will'],
]

export const agreementTypes = [
  ['Брачный договор', 'Marriage contract'],
  ['Соглашение о разделе имущества', 'Property division agreement'],
  ['Соглашение об алиментах', 'Alimony agreement'],
  ['Договор поручительства', 'Surety agreement'],
  ['Договор уступки права требования', 'Assignment agreement'],
]

const vocabularyConsent = {
  consentHeader: [
    'Согласия, Заявления, Договора',
    'Consents, Appications, Contracts',
  ],

  consentText1: [
    'Если вам из-за рубежа требуется оформить сделку с недвижимостью или заключить брачный договор, вам могут понадобиться нотариально оформленные документы, такие как согласие, заявление или договор.',
    'If you need to arrange a real estate deal or conclude a marriage contract while abroad, you may need notarized documents such as consent, application, or contract.',
  ],
  consentText2: [
    'Такие документы можно получить в ближайшем к вам Российском консульстве. Но обратите внимание, что сотрудники консульства не составляют тексты документов, они только заверяют заранее подготовленные в специальном формате проекты документов.',
    'You can obtain such documents at the nearest Russian consulate. However, please note that consulate staff do not draft document texts; they only certify prepared draft documents in a special format.',
  ],
  consentText3: [
    'Мы поможем вам составить требуемый документ, проконсультируем по записи на приём в консульство или запишем вас сами, и разместим готовый проект документа в нужном формате на консульском портале.',
    'We will assist you in drafting the required document, provide consultation on scheduling an appointment at the consulate, or schedule an appointment for you. Additionally, we will upload the prepared document draft in the required format to the consulate portal.',
  ],
  consent: ['Согласия', 'Consents'],

  typesOfConsents: [
    {
      section: ['Согласия', 'Consent'],
      types: [
        {
          id: 0,
          service: serviceTypes[0][1],
          additionalServiceType: consentTypes[0][1],
          text: [
            'На выезд ребёнка за пределы РФ',
            'Consent for the child to travel outside the Russian Federation.',
          ],
          icon: <Baby size={24} weight='light' />,
        },
        {
          id: 1,
          service: serviceTypes[0][1],
          text: [
            'От супруга на сделку с недвижимостью',
            'Consent from a spouse for a real estate transaction.',
          ],
          icon: <House size={24} weight='light' />,
        },
        {
          id: 2,
          service: serviceTypes[0][1],
          text: [
            'От супруга на сделку с долей в ООО',
            'Consent from a spouse for a transaction involving a share in an LLC.',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Заявления', 'Statements'],
      types: [
        {
          id: 3,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[0][1],
          text: [
            'На вступление / отказ от наследства',
            'For inheritance acceptance / refusal',
          ],
          icon: <FileText size={24} weight='light' />,
        },
        {
          id: 4,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[2][1],
          text: ['На снятие с регистрационного учёта', 'For deregistration'],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: 5,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[3][1],
          text: ['На выплату пенсии', 'For pension payment'],
          icon: <Coins size={24} weight='light' />,
        },
        {
          id: 6,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[4][1],
          text: ['Завещание', 'Will'],
          icon: <FileText size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Договора, соглашения', 'Contracts, agreements'],
      types: [
        {
          id: 7,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[0][1],
          text: ['Брачный договор', 'Contracts, agreements'],
          icon: <img src={marriageRingsImage} alt='logo' width={'24px'} />,
        },
        {
          id: 8,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[1][1],
          text: ['Соглашение о разделе имущества', 'Alimony agreement'],
          icon: <img src={marriageRingsImage} alt='logo' width={'24px'} />,
        },
        {
          id: 9,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[2][1],
          text: ['Соглашение об алиментах', 'Agreement on alimony'],
          icon: <Baby size={24} weight='light' />,
        },
        {
          id: 10,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[3][1],
          text: ['Договор поручительства', 'Surety agreement'],
          icon: <Coins size={24} weight='light' />,
        },
        {
          id: 11,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[4][1],
          text: [
            'Договор уступки права требования',
            'Assignment of claim agreement',
          ],
          icon: <Coins size={24} weight='light' />,
        },
      ],
    },
  ],
  registrationProcess: ['Процесс оформления', 'Process of registration'],
  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Согласие на выезд ребёнка за пределы РФ',
          'Permission for the child to travel outside the Russian Federation.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, требуемый при подаче документов на визу другого государства и далее для пересечения ребёнком границы РФ.',
          'Document required for the issuance of documents to another country and for the purpose of avoiding the child’s borders.',
        ],
      },
      {
        type: 'list',
        title: [
          'Бывает без сопровождения и с сопровождением:',
          'It can happen without an accompanying and with the accompanying',
        ],
        items: [
          [
            'дети до 2 лет: только с сопровождением',
            'children 2 years old: only with an accompanying',
          ],
          [
            'дети от 2 до 12 лет: либо в сопровождении лица старше 18 лет, либо под наблюдением перевозчика (без сопровождения)',
            'children from 2 to 12 years old: either with a witness older than 18 years or with the watch of the transporter (without an accompanying)',
          ],
          [
            'дети старше 12 лет: возможно без сопровождения',
            'children older than 12 years: it is possible without an accompanying',
          ],
        ],
      },
      {
        type: 'list',
        title: ['Требуемые документы:', 'Required documents:'],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
            'internal russian passport and/or foreign passport of the parent (or the lawful representative)',
          ],
          [
            'свидетельство о рождении ребёнка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
            'witness of the birth of the child (if the document of the foreign origin, it must be with an apostillum and notarized transfer to Russian language)',
          ],
          ['загранпаспорт РФ ребёнка', 'foreign passport of the child'],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of the residence in Russia (if there is no proclamation in Russia)',
          ],
          [
            'данные загранпаспорта лица, в сопровождении которого выезжает ребёнок (если согласие оформляется с сопровождением)',
            'data of the foreign passport of the person accompanying which the child (if the consent is issued with accompanying)',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Присутствие ребёнка при оформлении согласия не требуется.',
          'Absence of the child during the issuance of a consent is not required.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде ребёнка за пределы РФ с одним из родителей, согласие второго не требуется. Однако для оформления визы или ВНЖ страна въезда может потребовать предоставление согласий от обоих родителей.',
          'When leaving a child outside of Russia, the consent is not required. However, for the issuance of a visa or VAT, the consent may be required from both parents.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог недвижимости',
          'Consent of the spouse for the purchase / sale / donation / pledge of real estate',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, который оформляют, когда собственность является для супругов совместной.',
          'Document that is drawn up when the property is jointly owned by the spouses.',
        ],
      },
      {
        type: 'list',
        title: [
          'Так бывает в двух случаях, если:',
          'This happens in two cases if:',
        ],
        items: [
          [
            'недвижимость купили в браке',
            'the property was bought in marriage',
          ],
          [
            'был произведён капитальный ремонт на общие деньги',
            'major repairs were carried out with joint money',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Согласие не требуется, если недвижимость:',
          'Consent is not required if the property:',
        ],
        items: [
          ['куплена до вступления в брак', 'was purchased before marriage'],
          [
            'подарена или досталась в наследство одному из супругов',
            'was donated or inherited by one of the spouses',
          ],
          [
            'приватизирована в браке одним из супругов, при наличии отказа от участия в приватизации другого супруга',
            'was privatized in marriage by one of the spouses, with the refusal of the other spouse to participate in the privatization',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договору о разделе имущества',
            'belongs to only one spouse on the basis of a marriage contract or property division agreement',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
          ],
          [
            'документы, подтверждающие право собственности',
            'documents confirming ownership',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог / изменение доли в ООО',
          'Consent of the spouse for the purchase / sale / donation / pledge / change of share in LLC',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, предоставляемый во всех случаях, когда доля в ООО приобретается в браке на общие деньги.',
          'Document provided in all cases when a share in an LLC is acquired in marriage with joint funds.',
        ],
      },
      {
        type: 'list',
        title: [
          'Согласие не требуется, если доля:',
          'Consent is not required if the share:',
        ],
        items: [
          ['куплена до вступления в брак', 'was purchased before marriage'],
          [
            'подарена или досталась в наследство одному из супругов',
            'was donated or inherited by one of the spouses',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договору о разделе имущества',
            'belongs to only one spouse on the basis of a marriage contract or property division agreement',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
          ],
          [
            'наименование, адрес, ОГРН и ИНН компании, информация о размере доли',
            'name, address, OGRN and INN of the company, information about the size of the share',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на вступление / отказ от наследства',
          'Application for acceptance / renunciation of inheritance',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Чтобы принять наследство в России необходимо подготовить заявление на вступление в наследство и предъявить его нотариусу, который ведёт дело (часто оформляется в паре с доверенностью на ведение наследственного дела на человека, находящегося в России).',
          'To accept an inheritance in Russia, it is necessary to prepare an application for inheritance and present it to a notary who handles the case (often issued in conjunction with a Power of Attorney for handling the inheritance case to a person located in Russia).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление на вступление или отказ от наследства подаются в течение 6 месяцев со смерти наследодателя.',
          "The application for acceptance or renunciation of inheritance is submitted within 6 months from the date of the testator's death.",
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Отказ бывает адресным (в пользу другого наследника) или неадресный.',
          'Renunciation can be addressed (in favor of another heir) or non-addressed.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
        ],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ наследника (приоритет у внутреннего паспорта)',
            'internal Russian passport and/or foreign passport of the heir (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о смерти или данные о наследодателе: ФИО умершего, дата смерти, адрес регистрации места жительства на момент смерти',
            'death certificate or information about the testator: full name of the deceased, date of death, address of residence registration at the time of death',
          ],
        ],
      },
      {
        type: 'link',
        text: [
          'Смотреть также доверенность на вступление в наследство и ведение наследственного дела',
          'Also see the Power of Attorney for inheritance and handling the inheritance case',
        ],
        to: '/poa?section=poa&service=For+handling+inheritance+matters&id=6',
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на снятие с регистрационного учёта',
          'Application for deregistration',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Чтобы выписаться с места прописки в России через консульство, обычно оформляют два документа: заявление на снятие с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу на человека в России, который сможет обратиться в МВД от вашего имени.',
          'To deregister from your place of residence in Russia through the consulate, two documents are usually issued: an application for deregistration and a Power of Attorney for representation in state authorities on this issue to a person in Russia who will be able to contact the Ministry of Internal Affairs on your behalf.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
          ],
        ],
      },
      {
        type: 'link',
        text: [
          'Смотреть доверенность на представительство в госорганах',
          'See the Power of Attorney for representation in state authorities',
        ],
        to: '/poa?section=poa&service=For+representation+in+government+agencies&id=12',
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на оформление пенсии или восстановление её выплаты',
          'Application for pension registration or restoration of its payment',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если до переезда вы не получали пенсию, для её оформления вам потребуется заявление о назначении пенсии.',
          'If you did not receive a pension before moving, you will need an application for pension assignment.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если вы ранее уже получали пенсию, но выплаты прекратились после переезда за границу, для их восстановления потребуется предоставить заявление о выезде за пределы РФ.',
          'If you previously received a pension, but payments stopped after moving abroad, to restore them, you need to provide an application for departure from the Russian Federation.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для подготовки заявления потребуются:',
          'To prepare the application, the following are required:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          ['СНИЛС', 'SNILS'],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Завещание', 'Will'],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, в котором человек распоряжается своим имуществом на случай смерти: как именно и между какими людьми нужно разделить его собственность, долги и права.',
          'A document in which a person disposes of their property in the event of death: how and between which people to divide their property, debts, and rights.',
        ],
      },
      {
        type: 'list',
        title: [
          'Используют, например, в случаях, если требуется:',
          'Used, for example, in cases where it is necessary:',
        ],
        items: [
          [
            'завещать часть имущества человеку, не входящему в круг прямых наследников',
            'to bequeath part of the property to a person who is not in the circle of direct heirs',
          ],
          [
            'отказать в наследстве или изменить его долю одному из наследников',
            'to deny inheritance or change the share of one of the heirs',
          ],
          [
            'наложить какое-то дополнительное условие на получение наследства',
            'to impose some additional condition on the receipt of the inheritance',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Брачный договор', 'Marriage contract'],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, который определяет финансовые и имущественные отношения супругов во время брака и в случае развода.',
          'A document that defines the financial and property relations of spouses during the marriage and in the event of a divorce.',
        ],
      },
      {
        type: 'list',
        title: ['Что не включает:', 'What it does not include:'],
        items: [
          ['вопросы выплаты алиментов', 'issues of alimony payments'],
          [
            'порядок и способ воспитания детей',
            'the order and method of raising children',
          ],
          [
            'не может выполнять функции завещания',
            'cannot perform the functions of a will',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Брачный договор можно заключить как до брака, так и во время брака, вплоть до момента его окончания.',
          'A marriage contract can be concluded both before and during the marriage, until its termination.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления необходимо личное присутствие обоих сторон.',
          'Both parties must be present in person to complete the registration.',
        ],
      },
      {
        type: 'list',
        title: ['Требуемые документы:', 'Required documents:'],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ обоих сторон (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of both parties (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке (если уже состоят в нем)',
            'marriage certificate (if already married)',
          ],
          [
            'документы, подтверждающие право собственности на недвижимое или движимое имущество',
            'documents confirming ownership of real or movable property',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Соглашение о разделе имущества', 'Property Division Agreement'],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, по которому супруги в добровольном порядке делят между собой совместно нажитую собственность.',
          'A document by which spouses voluntarily divide jointly acquired property.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заключается во время брака, при его расторжении или после развода.',
          'Concluded during the marriage, upon its dissolution, or after a divorce.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления необходимо личное присутствие обоих сторон.',
          'Both parties must be present in person to complete the registration.',
        ],
      },
      {
        type: 'list',
        title: ['Требуемые документы:', 'Required documents:'],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ обоих сторон (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of both parties (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке или его расторжении',
            'marriage certificate or its dissolution',
          ],
          [
            'документы, подтверждающие право собственности на недвижимое или движимое имущество',
            'documents confirming ownership of real or movable property',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Соглашение об алиментах', 'Alimony Agreement'],
      },
      {
        type: 'paragraph',
        text: [
          'Обоюдная договоренность родителей по вопросам выплаты алиментов на содержание несовершеннолетних детей.',
          'Mutual agreement between parents on alimony payments for the support of minor children.',
        ],
      },
      {
        type: 'list',
        title: ['Что включает:', 'What it includes:'],
        items: [
          [
            'размер выплат (не может быть меньше положенного по закону)',
            'amount of payments (cannot be less than what is required by law)',
          ],
          ['периодичность', 'frequency'],
          [
            'способ: доля от дохода, фиксированная сумма или имущество',
            'method: share of income, fixed amount, or property',
          ],
          [
            'форма получения: наличными или банковским переводом',
            'form of receipt: in cash or by bank transfer',
          ],
        ],
      },
      {
        type: 'list',
        title: ['Требуемые документы:', 'Required documents:'],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ родителей (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the parents (priority given to the internal passport)',
          ],
          [
            'свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
            'birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian)',
          ],
          ['загранпаспорт РФ ребенка', 'foreign passport of the child'],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке или его расторжении',
            'marriage certificate or its dissolution',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Договор поручительства', 'Surety Agreement'],
      },
      {
        type: 'paragraph',
        text: [
          'Поручительство — это готовность исполнить кредитные обязательства заёмщика. Иными словами, если заёмщик не выплатит долг, поручитель обязан вернуть его кредитору вместе с просрочками.',
          "Suretyship is the willingness to fulfill the borrower's loan obligations. In other words, if the borrower does not repay the debt, the guarantor is obliged to return it to the creditor along with any overdue payments.",
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Договор может быть заключён в обеспечение как денежных, так и неденежных обязательств, а также в обеспечение обязательства, которое возникнет в будущем.',
          'The agreement can be concluded to secure both monetary and non-monetary obligations, as well as to secure an obligation that will arise in the future.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Поручителем может быть как физическое, так и юридическое лицо или ИП, однако финансовые организации охотнее дают кредиты, если поручителем выступает именно физическое лицо.',
          'The guarantor can be an individual, a legal entity, or a sole proprietor, but financial institutions are more willing to lend if the guarantor is an individual.',
        ],
      },
      {
        type: 'list',
        title: ['Требуемые документы:', 'Required documents:'],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ всех сторон (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of all parties (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'документы, подтверждающие финансовое состояние и трудовую занятость заёмщика и поручителя',
            'documents confirming the financial status and employment of the borrower and the guarantor',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Договор уступки права требования (цессии)',
          'Assignment Agreement',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если человеку (или организации) кто-то должен деньги или другие вещи (например, недвижимость), он может передать другому лицу право требовать долг.',
          'If someone (or an organization) is owed money or other things (such as real estate), they can transfer the right to claim the debt to another person.',
        ],
      },
      {
        type: 'list',
        title: ['Какие документы потребуются:', 'What documents are required:'],
        items: [
          [
            'документы, подтверждающие права, которые уступают по договору',
            'documents confirming the rights being assigned under the contract',
          ],
        ],
      },
      {
        type: 'list',
        title: ['Для физических лиц:', 'For individuals:'],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'Согласие супруга (если состоит в браке)',
            "spouse's consent (if married)",
          ],
        ],
      },
      {
        type: 'list',
        title: ['Для юридических лиц:', 'For legal entities:'],
        items: [
          [
            'Устав Организации и его изменения, если имели место быть',
            "organization's charter and its amendments, if applicable",
          ],
          [
            'Выписка из ЕГРЮЛ',
            'extract from the Unified State Register of Legal Entities (USRLE)',
          ],
          ['Свидетельство ОГРН, ИНН, КПП', 'OGRN, INN, KPP certificates'],
          [
            'Решение и приказ о назначении и приказ о назначении директора',
            'decision and order on the appointment of the director',
          ],
          ['Паспортные данные директора', 'passport details of the director'],
        ],
      },
    ],
  ],
  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите, что должно содержаться в документе. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект документа и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для заверения подготовленного документа',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submit an Application',
        cardText:
          'Describe what should be included in the document. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with them.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare the document draft, and upload it to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a placement number, which you will need to take with you to the consulate. The service will be completed within 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of your appointment, come to the consulate with your passport and placement number to have the prepared document certified.',
        cardImage: stampImage,
      },
    ],
  ],
  requiredDocumentsText1: [
    'Базовый комплект документов, которые потребуются для оформления документа, включает в себя:',
    'The basic set of documents required for document processing includes:',
  ],
  requiredDocumentsText2: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the processing of notarized documents, priority is given to the internal passport. In the event that the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, it is necessary to bring both passports to the consulate appointment.',
  ],
  userTypes: [
    {
      userName: ['Физические лица', 'Individuals'],
      desc: [
        [
          'Внутренний паспорт и/или загранпаспорт РФ для всех упомянутых в документе сторон',
          'Russian internal passport and/or Russian foreign passport for all parties mentioned in the document',
        ],
        [
          'Адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence abroad in Russian (if there is no registration in Russia)',
        ],
        [
          'Свидетельство о рождении ребёнка (если фигурирует ребёнок)',
          "Child's birth certificate (if a child is mentioned)",
        ],
        [
          'Свидетельство о браке и/или о его расторжении (если касается взаимоотношений супругов)',
          'Marriage certificate and/or divorce certificate (if it concerns the relationship between spouses)',
        ],
        [
          'Документы на имущество (если речь идёт о каком-то конкретном)',
          'Property documents (if referring to specific property)',
        ],
      ],
    },
    {
      userName: ['Юридические лица', 'Legal Entities'],
      desc: [
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if applicable',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
        ],
        [
          'Свидетельство ОГРН, ИНН, КПП',
          'Certificate of state registration number, tax identification number, and tax registration reason code',
        ],
        [
          'Решение и приказ о назначении директора',
          'Decision and order on the appointment of the director',
        ],
        ['Паспортные данные директора', 'Passport details of the director'],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ представителя (учредителя, участника, акционера)',
          'Russian internal passport and/or Russian foreign passport of the representative (founder, participant, shareholder)',
        ],
        [
          'СНИЛС (если имеется)',
          'Individual insurance account number (if available)',
        ],
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if applicable',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
        ],
      ],
    },
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '40',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60',
    },
  ],

  priceWithoutAppointment1: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '80',
    },
  ],

  priceWithAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '100',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '100',
    },
  ],
  consentsStatements: ['Согласия, заявления:', 'Consents, statements:'],
  contractsAgreements: ['Договора, соглашения:', 'Contracts, agreements:'],
  faq: [
    {
      userName: [
        'Как я могу выписаться из своей квартиры в РФ, находясь за рубежом?',
        'How can I deregister from  my apartment in the Russian Federation while abroad?',
      ],
      desc: [
        [
          'В консульстве можно оформить два документа (как правило их оформляют в паре): заявление (согласие) о снятии с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу.',
          'At the consulate you can draw up two documents (usually they are drawn up in pairs): an application (consent) for deregistration and a Power of Attorney for representation in government agencies on this issue.',
        ],
      ],
    },
    {
      userName: [
        'Если ребёнок выезжает за границу с одним из родителей, требуется ли на это согласие второго?',
        'If a child travels abroad with one of the parents, is the consent of the other required?',
      ],
      desc: [
        [
          'Для выезда ребёнка за пределы России — не требуется, однако его может запросить страна въезда при оформления визы (например, в страны шенгенской зоны), а также для получения ВНЖ другой страны.',
          'It is not required for a child to go outside of Russia, but it may be requested by the country of entry when applying for a visa (for example, to countries in the Schengen zone), as well as to obtain a residence permit of another country.',
        ],
        [
          'Необходимость получения согласия от родителя в таких случаях не зависит от того, в разводе супруги или нет.',
          'The need to obtain a consent from a parent in such cases does not depend on whether the spouse is divorced or not.',
        ],
      ],
    },
    {
      userName: [
        'Когда требуется согласие супруга на сделку?',
        "When is a spouse's consent required for a transaction?",
      ],
      desc: [
        [
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',
          'Consent is required in every case when it comes to property acquired jointly during marriage.',
        ],
        [
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
          'It does not include objects acquired by one of the spouses before marriage, received as a gift or by inheritance.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question:
          'Как я могу выписаться из своей квартиры в РФ, находясь за рубежом?',

        answer:
          'В консульстве можно оформить два документа (как правило их оформляют в паре): заявление (согласие) о снятии с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу.',
      },
      {
        question:
          'Если ребёнок выезжает за границу с одним из родителей, требуется ли на это согласие второго?',

        answer:
          'Для выезда ребёнка за пределы России — не требуется, однако его может запросить страна въезда при оформления визы (например, в страны шенгенской зоны), а также для получения ВНЖ другой страны.',

        answer2:
          'Необходимость получения согласия от родителя в таких случаях не зависит от того, в разводе супруги или нет.',
      },
      {
        question: 'Когда требуется согласие супруга на сделку?',

        answer:
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',

        answer1:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
      },
    ],
    [
      {
        question:
          'How can I check out of my apartment in the Russian Federation while abroad?',

        answer:
          'At the consulate you can draw up two documents (usually they are drawn up in pairs): an application (consent) for deregistration and a Power of Attorney for representation in government agencies on this issue.',
      },
      {
        question:
          'If a child travels abroad with one of the parents, is the consent of the other required?',

        answer:
          'It is not required for a child to travel outside of Russia, but it may be requested by the country of entry when applying for a visa (for example, to countries in the Schengen zone), as well as to obtain a residence permit of another country.',

        answer1:
          'The need to obtain consent from a parent in such cases does not depend on whether the spouse is divorced or not.',
      },
      {
        question: "When is a spouse's consent required for a transaction?",

        answer:
          'Consent is required in every case when it comes to property acquired jointly during marriage.',

        answer1:
          'It does not include objects acquired by one of the spouses before marriage, received as a gift or by inheritance.',
      },
    ],
  ],
  workflow: {
    requestDocumentType: {
      title: ['Какой документ вам необходим?', 'What document do you need?'],
      options: [...serviceTypes, ['Не знаю', 'Not sure']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    requestConsentType: {
      title: [
        'Какое согласие вам необходимо?',
        'What type of consent do you need?',
      ],
      selectPlaceholder: ['Тип согласия', 'Type of consent'],
      options: [...consentTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Или опишите своими словами, на что должно быть выдано согласие',
        'Or describe in your own words, what should be given as consent',
      ],
    },
    requestApplicationType: {
      title: [
        'Какое заявление вам необходимо?',
        'What application do you need?',
        'Какое заявление вам необходимо?',
        'What application do you need?',
      ],
      options: [...applicationTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words which document is required',
      ],
    },
    requestAgreementType: {
      title: [
        'Какой договор или соглашение вам необходимо?',
        'Which contract or agreement do you need?',
        'Какой договор или соглашение вам необходимо?',
        'Which contract or agreement do you need?',
      ],
      options: [...agreementTypes, ['Другой документ', 'Other document']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words which document is required',
      ],
    },
    requestAdditionalInformation: {
      title: ['Ваши комментарии', 'Your comments'],
      additionalInfo: [
        'Опишите своими словами, для каких целей будет использоваться документ',
        'Describe in your own words the purpose for which the document will be used',
      ],
    },
    requestGuarantorAndBorrower: {
      titleGuarantor: ['Кто является поручителем?', 'Who is the guarantor?'],
      titleBorrower: ['Кто является заёмщиком?', 'Who is the borrower?'],
      options: [
        ['Физическое лицо', 'Individual'],
        ['Юридическое лицо', 'Legal entity'],
      ],
    },
    requestCreditor: {
      title: ['Кто является кредитором?', 'Who is the creditor?'],
      options: [
        ['Физическое лицо', 'Individual'],
        ['Юридическое лицо', 'Legal entity'],
      ],
      subjectOfGuaranteeTitle: [
        'Предмет поручительства',
        'Subject of guarantee',
      ],
      additionalInfo: [
        'Опишите своими словами. Например, ипотечный договор на покупку недвижимости',
        'Describe in your own words. For example, a mortgage agreement for the purchase of real estate',
      ],
    },
    requestPersonWhoTransferringRights: {
      whoTitle: ['Кто уступает права?', 'Who is transferring the rights?'],
      toWhomTitle: [
        'Кому уступаются права?',
        'To whom are the rights being transferred?',
      ],
      options: [
        ['Физическое лицо', 'Individual'],
        ['Юридическое лицо', 'Legal entity'],
      ],
    },
    requestRightsAreBeingTransferred: {
      title: [
        'Какие права переуступаются?',
        'What rights are being transferred?',
      ],
      additionalInfo: [
        'Опишите своими словами. Например, право на получение квартиры или право взыскать задолженность по кредиту',
        'Describe in your own words. For example, the right to receive an apartment or the right to collect debt on a loan',
      ],
    },
  },
}
export default vocabularyConsent
